.outdated-browser-wrapper {
	margin-top: 88px; 
}

.outdated-browser {
	z-index: z('outdated-browser');
	@include antialias;
	position: absolute;
	top: 0; right: 0; left: 0;
	font-size: 16px;
	padding: 30px;
	color: $c--white;
	text-align: center;
	background-color: mix($c--negative, $c--white, 85%);
	border-bottom: 4px solid mix($c--negative, $c--black, 85%);

	a {
		text-decoration: none;
		font-weight: bold;
		color: $c--white;
		border-bottom: 2px solid $c--white;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}
