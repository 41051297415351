$icon-size: 1em;

/*---

Available icons:

```
<ul class="inline-children--block sg-icons">
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#check"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-down"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-left"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-right"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-down"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-left"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-right"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-thin-up"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#cross"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#link"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#mail"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#minus"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#phone"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#plus"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-down"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-left"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-right"></use></svg></li>
	<li><svg class="icon"><use xlink:href="/assets/img/sprite.svg#triangle-up"></use></svg></li>
</ul>
```

Use `inline` when used with text:

```
<p><svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg> Lorem ipsum dolor sit amet, consectetur <svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg> adipisicing elit. <svg class="icon icon--inline"><use xlink:href="/assets/img/sprite.svg#chevron-up"></use></svg></p>
```

Use `icon-text` for vertical alignment:

```
<div style="max-width: 10em">
	<span class="icon-text">
		<span class="icon-text__t">Icon text, description or title</span>

		<svg class="icon-text__i icon">
			<use xlink:href="/assets/img/sprite.svg#plus"></use>
		</svg>
	</span>
</div>
```

```
<div style="max-width: 10em">
	<span class="icon-text">
		<svg class="icon-text__i icon">
			<use xlink:href="/assets/img/sprite.svg#plus"></use>
		</svg>

		<span class="icon-text__t">Icon text, description or title</span>
	</span>
</div>
```

*/

.icon {
	display: inline-block;
	fill: currentColor;
	vertical-align: top;
	width: $icon-size;
	height: $icon-size;

	&--inline {
		// y = line-height measurment was taken at
		// x =  how far off centre the baseline is, at `y`
		$x: 10;
		$y: 90;
		vertical-align: n($x/$y * 100%);
	}
}

.icon-text {
	display: inline-block;
	position: relative;

	&__i {
		width: $icon-size;
		height: $icon-size;
		position: absolute;
		top: 50%; right: 0;
		transform: translateY(-50%);

		&:first-child {
			right: auto; left: 0;
		}
	}

	&__t {
		display: inline-block;
		padding-right: $icon-size + $rel-spacing-unit--small;

		&:last-child {
			padding: {
				right: 0;
				left: $icon-size + $rel-spacing-unit--small;
			}
		}
	}
}
