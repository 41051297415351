.site-footer {
	position: relative;
	z-index: z('site-footer');
}

.lang-switcher { text-align: right; }
.button.is-text {
	height: auto;
	padding: 0; margin-right: 1rem;
	outline: none;
	text-transform: none; 
	border-radius: 0;
	text-decoration: none;
	font-weight: normal; font-size: 14px;
	border: none; background: none;
	&:focus,
	&:active { background: none; outline: none; box-shadow: none; }
	&:hover { background: none; text-decoration: none; color: #000; border-bottom: 1px solid #000; }
}