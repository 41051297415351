.is-hidden {
	@include hidden;
}

.vh {
	@include vh;
}

.cf {
	@include cf;
}