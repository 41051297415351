/*---

Quickly build complex layouts.

```
<div class="sg-example-grid">
	<div class="row flush-first--1 flush-first--tablet-2">
		<div class="column width--tablet-12"><span class="sg-panel">12 (tablet)</span></div>
		<div class="column width--tablet-12"><span class="sg-panel">12 (tablet)</span></div>
	</div>
	<div class="row">
		<div class="column width--8"><span class="sg-panel">8</span></div>
		<div class="column width--8"><span class="sg-panel">8</span></div>
		<div class="column width--8"><span class="sg-panel">8</span></div>
	</div>
	<div class="row">
		<div class="column width--6"><span class="sg-panel">6</span></div>
		<div class="column width--6"><span class="sg-panel">6</span></div>
		<div class="column width--6"><span class="sg-panel">6</span></div>
		<div class="column width--6"><span class="sg-panel">6</span></div>
	</div>
	<div class="row">
		<div class="column width--4"><span class="sg-panel">4</span></div>
		<div class="column width--4 push--4"><span class="sg-panel">4 (Push 4)</span></div>
		<div class="column width--4 push--4"><span class="sg-panel">4 (Push 4)</span></div>
		<div class="column width--4 push--8"><span class="sg-panel">4 (Push 8)</span></div>
	</div>
	<div class="row">
		<div class="column width--10 push--13"><span class="sg-panel">10 (Push 13)</span></div>
		<div class="column width--10 pull--9"><span class="sg-panel">10 (Pull 9)</span></div>
	</div>
	<div class="row">
		<div class="column width--20 push--2 width--tablet-10 push--tablet-7"><span class="sg-panel">Responsive width, push and pull</span></div>
	</div>
</div>
```

*/

.row {
	@include cf;
	margin-left: n($grid-column-gutter);

	@include mq(tablet) {
		margin-left: n($grid-column-gutter--tablet);
	}

	@include mq(desktop) {
		margin-left: n($grid-column-gutter--desktop);
	}

	&--compact {
		> .column {
			margin-top: $spacing-unit--small;

			@include mq(tablet) {
				margin-top: $spacing-unit;
			}
		}
	}
}

.column {
	position: relative;
	float: left;
	width: 100%;
	margin-top: $grid-column-gutter;
	padding-left: $grid-column-gutter;

	@include mq(tablet) {
		margin-top: $grid-column-gutter--tablet;
		padding-left: $grid-column-gutter--tablet;
	}

	@include mq(desktop) {
		margin-top: $grid-column-gutter--desktop;
		padding-left: $grid-column-gutter--desktop;
	}
}
