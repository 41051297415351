/*

	Box sizing

*/

* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
	}
}



/*

	Basic document styling

*/

html {
	font-size: $html-font-size;
	min-height: 100%;
	overflow-y: scroll;
	-webkit-text-size-adjust: none;
}

body {
	background-color: $c--body-bg;
	color: $c--body-colour;
	font-family: $stack;
	font-size: $p;
	line-height: $lh;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-DemiItalic.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-DemiItalic.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-DemiItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-MonoItalic.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-MonoItalic.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-MonoItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-Mono.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-Mono.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-Mono.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-BoldItalic.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-BoldItalic.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-Bold.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-Bold.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-Book.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-Book.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-LightItalic.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-LightItalic.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-MediumItalic.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-MediumItalic.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-Medium.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-Medium.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-Light.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-Light.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-BookItalic.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-BookItalic.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Maison Neue';
    src: url('/assets/static/fonts/MaisonNeue-Demi.woff2') format('woff2'),
        url('/assets/static/fonts/MaisonNeue-Demi.woff') format('woff'),
        url('/assets/static/fonts/MaisonNeue-Demi.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

.site-wrapper {

	text-align: center;

	[aria-hidden="true"] { opacity: 0.2; }
	font-weight: $w--normal;


}



/*

	Text selection/highlighting

*/

@include selection {
	background: $c--brand-primary;
	color: $c--white;
	text-shadow: none;
}
