h1, h2, h3, h4, h5, h6,
p,
blockquote,
pre,
dl, dd,
ol, ul,
form, legend,
table, th, td, caption,
hr,
figure {
	margin: 0;
	padding: 0;
}