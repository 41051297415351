.site-header {
//	z-index: z('site-header');
	@include soft--ends($spacing-unit--large);

	h1 {
		position: absolute; top: $spacing-unit--large; left: $spacing-unit; text-indent: -990em; overflow: hidden; margin-bottom: $spacing-unit; z-index: 1;
		a {
			display: block; pointer-events: auto;
			img, svg, video {
				display: block;
			}
			video {
				&.off {
					position: absolute; top: 0; left: 0; opacity: 0;
				}
			}
		}
	}
	nav {
		text-transform: uppercase; padding: 0; position: relative; clear: both;

		ul {
			li {
				display: inline-block; vertical-align: top; margin: 0 20px; position: relative;
				a {
					text-decoration: none; color: #000; position: relative; padding: $spacing-unit--x-small; display: block;
					span {
						display: block; position: relative; z-index: 1; transition: color .4s ease; line-height: 1.1; font-size: rem(16px);
						strong {
							display: block; font-size: rem(21px); font-weight: $w--bold;
							small {
								display: block; font-size: rem(15px);
							}
						}
						em {
							font-style: normal;
						}
					}
					&:before {
						display: block; content: ""; width: 13px; height: 8px; position: absolute; top: 0; left: 50%; transform: translateX(-50%); pointer-events: none; background: url(data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjI3cHgiIGhlaWdodD0iMTdweCI+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9IlBTZ3JhZF8wIiB4MT0iMCUiIHgyPSIxMDAlIiB5MT0iMCUiIHkyPSIwJSI+CiAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0icmdiKDAsMTY5LDIyOCkiIHN0b3Atb3BhY2l0eT0iMSIgLz4KICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9InJnYigyMjksMCwxMTgpIiBzdG9wLW9wYWNpdHk9IjEiIC8+CjwvbGluZWFyR3JhZGllbnQ+Cgo8L2RlZnM+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigwLCAwLCAwKSIKIGQ9Ik0yNi42NTQsMy4xOTUgTDE2LjYzMiwxMy4yMTggTDE2LjYzMiwxMy4yMTggTDEzLjUwMCwxNi4zNTAgTDEwLjM2OCwxMy4yMTggTDEwLjM2OCwxMy4yMTggTDAuMzQ2LDMuMTk1IEwzLjQ3OCwwLjA2MyBMMTMuNTAwLDEwLjA4NiBMMjMuNTIyLDAuMDYzIEwyNi42NTQsMy4xOTUgWiIvPgo8cGF0aCBmaWxsPSJ1cmwoI1BTZ3JhZF8wKSIKIGQ9Ik0yNi42NTQsMy4xOTUgTDE2LjYzMiwxMy4yMTggTDE2LjYzMiwxMy4yMTggTDEzLjUwMCwxNi4zNTAgTDEwLjM2OCwxMy4yMTggTDEwLjM2OCwxMy4yMTggTDAuMzQ2LDMuMTk1IEwzLjQ3OCwwLjA2MyBMMTMuNTAwLDEwLjA4NiBMMjMuNTIyLDAuMDYzIEwyNi42NTQsMy4xOTUgWiIvPgo8L3N2Zz4=) no-repeat 50% 50%; background-size: 100%; margin-top: -16px; opacity: 0; transition: all .4s ease;
					}
					&:after {
						display: block; content: ""; height: 3px; width: 0; position: absolute; bottom: 0; left: 0; z-index: 0; background: $c--black;
						background-size: 200% 200%; transition: width .4s ease;
					}
					&.on {
						&:before {
							margin-top: -8px; opacity: 1;
						}
						&:after {
							 width: 100%; transform: none; background-size: 100%; @include jxGradient; background-size: 200%; animation: gradientMove 20s ease infinite;
						}
					}
				}
				
				&.unpublished {
					a {
						pointer-events: none;
						&:after {
							display: inline-block; content: "SOON"; width: auto; height: auto; color: $c--white; line-height: .9; padding: .3em .4em .15em; position: relative; z-index: 1; @include jxGradient; background-size: 200%; animation: gradientMove 20s ease infinite; position: absolute; bottom: 100%; top: auto; left: 50%; transform: translateX(-50%) rotate(-2deg); letter-spacing: 1px; opacity: 1; margin-bottom: 0px; font-size: rem(16px); font-weight: 400;

						}
					}
				}
				
				&.past {
					a {
						&:after {
							opacity: 0; transition: opacity .4s, margin .4s; margin-bottom: -10px;
 						}
						&:hover {
							&:after {
								display: inline-block; content: "PAST"; width: auto; height: auto; color: $c--white; line-height: .9; padding: .25em .4em .1em; position: relative; z-index: 1; background: #000; position: absolute; bottom: 100%; top: auto; left: 50%; transform: translateX(-50%) rotate(-2deg); letter-spacing: 1px; opacity: 1; margin-bottom: 0px; font-weight: rem(15px); font-weight: 500;
	
							}
						}
					}
				}
			}
		}
		
		.hamburger {
			position: absolute; top: 48px; left: $spacing-unit; transform: translateY(-44%); z-index: 3; @include mq($from: tablet) { display: none }
		}
	
	}

	.rsvp,
	.sharer {
		width: 160px;
	}

	.rsvp {
		position: fixed; top: $spacing-unit--x-small; right: $spacing-unit; z-index: 666; margin-top: 4px;
		padding: 1.25rem 2rem;
		transition: all 0.4s cubic-bezier(0.94,.01,.72,.47);
		&:after {
			content: '\1F389\00A0\00A0';
			display: inline-block;
			position: absolute; right: -10px;
			font-size: 20px;
			opacity: 0;
			transform-origin: 0% 100%;
			transition: all 0.6s cubic-bezier(0.75, 0, 0.125, 1);
		}
		&:hover {
			padding: 1.25rem 2.5rem 1.25rem 1.5rem;
			&:after {
				opacity: 1;
				margin-top: 0; right: 20px;
				animation: shake 3s 0.4s infinite; 
			}
		}
	}

	.sharer {
		position: fixed; top: 5rem; right: $spacing-unit; z-index: 666; margin-top: 4px;
		padding: calc(1.25rem - 2px) 2rem;
		transition: all 0.4s cubic-bezier(0.94,.01,.72,.47);
		&:after {
			content: '🚀';
			display: inline-block;
			position: absolute; margin-top: 20px; right: 30px;
			font-size: 20px;
			opacity: 0;
			transition: all 0.6s cubic-bezier(0.75, 0, 0.125, 1);
			text-shadow: 2px 2px 10px rgba(0,0,0,.2);
		}
		&:hover {
		padding: calc(1.25rem - 2px) 3rem calc(1.25rem - 2px) 1rem; 
			&:after {
				opacity: 1;
				margin-top: 1px; right: 20px;
				transition: all 0.5s cubic-bezier(0.94,.01,.72,.47);
				animation: shake 3s 0.5s infinite;
			}
		}
	}
	
	.parties-over {
		.sharer {
			top: $spacing-unit;
		}
	}

	@include mq($to: tablet){
		h1 {
			position: relative; top: 0; float: left;
		}
		nav {
			ul {
				position: relative; margin: 0 $spacing-unit; padding: $spacing-unit--small $spacing-unit--small calc(#{$spacing-unit--small} - 5px);
				&:before, &:after {
					display: block; content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 2px; @include jxGradient; background-size: 200%; animation: gradientMove 20s ease infinite;
				}
				&:after {
					top: auto; bottom: 0;
				}
				li {
					display: block; margin: 0; position: relative;
					a {
						display: none; text-align: left; padding: 0; padding-left: $spacing-unit--large*2;
						span strong {
							font-size: rem(24px);
						}
						&.on {
							display: block;
							&:before, &:after {
								display: none;
							}
						}
					}
				}
			}
			&.is-active {
				height: 94px;
				ul {
					position: absolute; background: #fff; width: calc(100% - #{$spacing-unit*2}); z-index: 2; 
					li {
						margin-bottom: $spacing-unit;
						&:last-child {
							margin-bottom: 0;
						}
						a:after {
							position: absolute; left: 0; top: 50%; transform: translateY(-50%) rotate(-2deg); bottom: auto;
						}
						a {
							display: block;
						}
					}
				}
			}
		}
		aside {
			float: right; text-align: right; margin-right: $spacing-unit;
			.rsvp,
			.sharer {
				display: inline-block; position: static; margin-left: $spacing-unit--x-small;
			}
		}
	}
	
	@include mq($to: tablet-small){
		h1 {
			float: none; width: calc(100% - #{$spacing-unit*2});
			video {
				margin: 0 auto;
			}
		}
		aside {
			position: fixed; bottom: 0; left: 0; width: 100vw; z-index: 667; background: #fff;
			.sharer, .rsvp {
				display: block; float: left; width: 50%; height: 60px; line-height: 60px; padding: 0 !important; margin: 0;
			}
			.sharer {
				border: 0; background: rgba(#ed1849, .05);
			}
			&.parties-over {
				.sharer { width: 100%; }
			}
		}
	}

	@media screen and (max-height: 500px) and (orientation: landscape){
		aside {
			position: fixed; bottom: 0; left: 0; width: 100vw; z-index: 667; background: #fff;
			.sharer, .rsvp {
				position: static; display: block; float: left; width: 50%; height: 60px; line-height: 60px; padding: 0 !important; margin: 0;
			}
			.sharer {
				border: 0; background: rgba(#ed1849, .05);
			}
		}
	}
	@include mq($to: phone){
		nav {
			ul {
				padding-left: 0; padding-right: 0;
				li {
					&.unpublished a:after { font-size: .8rem; }
					a {
						padding-left: $spacing-unit--x-large; padding-right: 0;
					}
				}
			}
			.hamburger { left: $spacing-unit--large; }
		}
	}
}

.fancybox-content aside {
	position: fixed; top: $spacing-unit--x-small; right: $spacing-unit; z-index: 666; margin-top: 4px;
	.rsvp,
	.sharer {
		display: block; width: 160px; height: 55px; margin-bottom: calc(#{$spacing-unit--x-small} + 4px);
	}
	@include mq($to: tablet-small){
		position: fixed; top: auto; bottom: 0; left: 0; width: 100vw; z-index: 667; background: #fff;
		.sharer, .rsvp {
			display: block; float: left; width: 50%; height: 60px; line-height: 60px; padding: 0 !important; margin: 0;
		}
		.sharer {
			border: 0; background: rgba(#ed1849, .05);
		}
	}
	@media screen and (max-height: 500px) and (orientation: landscape){
		position: fixed; bottom: 0; left: 0; width: 100vw; z-index: 667; background: #fff;
		.sharer, .rsvp {
			position: static; display: block; float: left; width: 50%; height: 60px; line-height: 60px; padding: 0 !important; margin: 0;
		}
		.sharer {
			border: 0; background: rgba(#ed1849, .05);
		}
	}
}
