/*---

```
<div class="breadcrumb">
	<a href="#0">Home</a>

	<svg class="icon">
		<use xlink:href="/assets/img/sprite.svg#chevron-thin-right"></use>
	</svg>

	<a href="#0">Foo</a>

	<svg class="icon">
		<use xlink:href="/assets/img/sprite.svg#chevron-thin-right"></use>
	</svg>

	<span>Bar</span>
</div>
```

*/

.breadcrumb {
	@include cf;
	line-height: $lh--small;

	a,
	.icon,
	span {
		display: inline-block;
		margin-right: rem(5px);
		vertical-align: top;

		&:last-child {
			margin-right: 0;
		}
	}

	.icon {
		$x: 3;
		$y: 20;
		vertical-align: n($x/$y * 100%);
	}
}
