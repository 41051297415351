/*---

A list of groups of terms and descriptions:

```
<dl>
	<dt>Delectus veniam ipsa animi</dt>
	<dd>Lorem ipsum dolor sit amet, consectetur adipisicing elit. At sunt dolorum quas aperiam, exercitationem quia ducimus odit aliquam. Vel dolore facilis, cumque reiciendis atque, commodi id aliquid et sapiente esse!</dd>

	<dt>Lorem ipsum dolor</dt>
	<dd>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error quibusdam, quae asperiores dolores, quos, amet facilis veritatis vel tenetur necessitatibus voluptatem. Esse iure nihil harum, voluptatum similique fugit quas eius!</dd>

	<dt>Autem consequatur</dt>
	<dd>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora mollitia eveniet est, numquam in asperiores, laudantium, porro provident eum inventore facere laborum amet dolorum! Vitae at itaque natus, aspernatur illum.</dd>

	<dt>Quas reiciendis cupiditate </dt>
	<dd>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Error dolorum fugiat officia sequi, obcaecati, nulla earum quod cum ipsum assumenda ea corporis rerum dolorem incidunt ipsa, recusandae tempore quo eius.</dd>

</dl>
```

*/

dl {
	@include mq(tablet) {
		@include cf;
	}
}

dt,
dd {
	@include mq(tablet) {
		float: left;
	}
}

dt {
	margin-top: $rel-spacing-unit;
	font-weight: $w--bold;

	&:first-child {
		margin-top: 0;

		+ dd {

			@include mq(tablet) {
				margin-top: 0;
			}
		}
	}

	@include mq(tablet) {
		clear: left;
		width: 25%;
	}
}

dd {
	margin-top: $rel-spacing-unit--small;

	@include mq(tablet) {
		margin-top: $rel-spacing-unit;
		padding-left: $spacing-unit--small;
		width: 75%;
	}
}
