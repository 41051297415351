@mixin width-push-pull($modifier: '') {

	$modifierClass: '';

	@if $modifier != '' {
		$modifierClass: '#{$modifier}-';
	}
	@else {
		$modifier: 'base';
	}

	@for $i from 1 through $grid-column-count {

		@if $dev-mode {

			.width--#{$modifierClass}#{$i} {
				width: grid-calc($i);
			}

			.push--#{$modifierClass}#{$i} {
				left: grid-calc($i);
			}

			.pull--#{$modifierClass}#{$i} {
				left: 0 - grid-calc($i);
			}

		} @else {

			@if index( map-deep-get($grid-bp, $modifier, width) , $i) or map-deep-get($grid-bp, $modifier, width) == true {
				.width--#{$modifierClass}#{$i} {
					width: grid-calc($i);
				}
			}

			@if index( map-deep-get($grid-bp, $modifier, push) , $i) or map-deep-get($grid-bp, $modifier, push) == true {
				.push--#{$modifierClass}#{$i} {
					left: grid-calc($i);
				}
			}

			@if index( map-deep-get($grid-bp, $modifier, pull) , $i) or map-deep-get($grid-bp, $modifier, pull) == true {
				.pull--#{$modifierClass}#{$i} {
					left: 0 - grid-calc($i);
				}
			}

		}

	}

	.push--#{$modifier}-0 {
		left: 0;
	}

}

@include width-push-pull;

@each $name, $min-width in $breakpoints {

	@include mq($name) {

		@include width-push-pull(#{$name});

	}

}