/*---

Extended quotations, with support for citation:

```
<blockquote class="blockquote">
	<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

	<cite class="blockquote__meta">Author name here, Role/department</cite>
</blockquote>
```

```
<blockquote class="blockquote blockquote--right">
	<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

	<cite class="blockquote__meta">Author name here, Role/department</cite>
</blockquote>
```

```
<blockquote class="blockquote blockquote--left">
	<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

	<cite class="blockquote__meta">Author name here, Role/department</cite>
</blockquote>
```

```
<blockquote class="blockquote blockquote--centre">
	<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>

	<cite class="blockquote__meta">Author name here, Role/department</cite>
</blockquote>
```

*/

.blockquote {
	max-width: 45em;
	position: relative;

	p {
		font-size: $p--medium;
		line-height: $lh--large;

		@include mq(tablet-small) {
			font-size: $p--large;
		}
	}

	cite {
		display: inline-block;
		font-size: $p--small;
		margin-top: $rel-spacing-unit--small;

		@include mq(tablet-small) {
			font-size: $p;
		}
	}

	&--left,
	&--right {
		max-width: 50%;
	}

	&--left {
		padding-right: $spacing-unit;
		float: left;
	}

	&--right {
		padding-left: $spacing-unit;
		float: right;
	}

	&--centre {
		@include push--auto;
		text-align: center;
	}
}
