fieldset {
	border: none;
	margin: 0;
	padding: 0;
}

input,
select,
textarea {
	appearance: none;
	background-clip: border-box;
	margin: 0;
	outline: 0;
	text-align: left;
	vertical-align: top;
	width: 100%;
}

input[type='checkbox'],
input[type='radio'] {
	width: auto;
	height: auto;
}

input[type='checkbox'] {
	appearance: checkbox;
}

input[type='radio'] {
	appearance: radio;
}

textarea,
select[size],
select[multiple] {
	height: auto;
}

// remove dropdown for IE10+
select::-ms-expand {
	display: none;
}

// remove IE11 select element selected option state
select:focus::-ms-value {
	background-color: transparent;
	color: inherit;
}

// Allow only vertical resizing of textareas
textarea {
	resize: vertical;
}

// remove search controls
input[type='search']::-webkit-search-decoration {
	display: none;
}


// button
button {
	appearance: none;
	background: none;
	border: none;
	margin: 0;
	padding: 0;
}

button,
.button {
	cursor: pointer;
	display: inline-block;
	font: normal 16px/1 sans-serif;
	outline: 0;
	overflow: visible;
	text-align: center;
	text-decoration: none;
	vertical-align: top;
	width: auto;

	&:hover,
	&:focus {
		text-decoration: none;
	}
}

// disabled states
[disabled],
[disabled] * {
	box-shadow: none;
	cursor: not-allowed;
	user-select: none;
}