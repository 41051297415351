/*---

Clickable buttons:

```
<div class="button-wrapper">
	<button class="button">Button</button>
</div>

<div class="button-wrapper">
	<a href="#0" class="button">Button (link)</a>
</div>
```

Alternate styles using `secondary`, `positive` and `negative` modifiers:

```
<div class="button-wrapper">
	<button class="button button--secondary">Button: Secondary</button>
</div>

<div class="button-wrapper">
	<button class="button button--positive">Button: Positive</button>
</div>

<div class="button-wrapper">
	<button class="button button--negative">Button: Negative</button>
</div>
```

And sized using `large` and `small`:

```
<div class="button-wrapper">
	<button class="button button--large">Button: Large</button>
</div>

<div class="button-wrapper">
	<button class="button button--small">Button: Small</button>
</div>
```

Other states:

```
<div class="button-wrapper">
	<button class="button" disabled>Button: Disabled</button>
</div>

<div class="button-wrapper">
	<button>Unstyled button</button>
</div>
```

*/

// basic buttons

.button {
	background-color: $c--white;
	color: $c--black;
	font-family: $stack;
	font-size: $p;
	font-weight: 500;
	padding: rem($spacing-unit--small) rem($spacing-unit); font-size: rem(15px);
	position: relative;
	text-decoration: none;
	text-transform: uppercase; border: 2px solid; line-height: 1; letter-spacing: 1px;
	&:focus {
		[data-whatinput='keyboard'] & {
			box-shadow: 0px 0px 3px $c--brand-primary, 0px 0px 6px $c--brand-primary, 0px 0px 9px $c--brand-primary;
		}
	}

	&:active {

	}

	&[disabled] {
		&,
		&:focus {
			background-color: $c--grey-light;
			color: mix($c--grey-light, $c--black, 60%);
		}
	}
	
	svg {
		display: block; width: 24px; position: absolute; right: $spacing-unit--small; top: 50%; transform: translateY(-50%);
	}
}


// colours

.button--secondary {
	background-color: $c--black; color: $c--white;
}

.button--brand {
	background: $c--brand-primary; color: $c--white; border: none;
	background: linear-gradient(45deg, #c30e8f 0%,#e50054 28%,#f39200 100%);
}

.button--positive {
	background-color: $c--positive;
}

.button--negative {
	background-color: $c--negative;
}

.button--photo {
	padding-left: $spacing-unit--x-large;
	&:before {
		display: block; content: ""; position: absolute; top: 50%; left: calc(#{$spacing-unit--small} + 3px); margin-top: -7px; width: 23px; height: 13px; background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAaCAMAAAD/jJ6+AAAAYFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////98JRy6AAAAH3RSTlMA+bcq8j3Bf04TZenfCbFezuOTi0JTNxutc25JJQ+gz1jruwAAAR9JREFUOMudVO2SgyAMBBUURa31q995/7e8FYrDXYZ2zv0VM+tmEwLiL9r7mNmCqLDZeG/FZ1QXSRHkpUpz25slBntLlOiHNyOrc2PyOiOPoRccuSYPnSdSMa7Bcxlny9DD9RfZuMLNzjavblSB3ziDJjJit2x/QjZS1Ssi6Jyq7Qe7G1KF+12h6MNnZt8j+A+YVK54od6zlt7EmagLCoubi0bUEZ19OVkFtpwRwEs8shqMCeOFm62e9PxJhjoI4M+Us2MH3Ry04FhOogF7cWcKtacQL6Jll0fnT6Rb5w/8htHhuU7TmRnXcNIMa3UiyCdb5YPUbskSg+THtA74Sh4TX4IV+h+WgK+YGju+YocX+Pv1OHz5vl/t4w/HP5+lH64gNmeC4oyqAAAAAElFTkSuQmCC) no-repeat 0 0; background-size: cover;
	}
}

.button--spotify {
	background-color: #1db954; color: $c--white; padding-right: $spacing-unit--x-large;
}

.button--pinterest {
	background-color: #bd081c; color: $c--white; padding-right: $spacing-unit--x-large;
}

// sizes

.button--large {
	padding: rem(22px) rem(30px);

	@include mq(tablet) {
		padding: rem(25px) rem(40px);
		font-size: $p--medium;
	}
}

.button--small {
	font-size: $p--small;
	padding: rem(12px);
}
