/*---

A div that's hidden on mobile, and appears at tablet

```
<div class="hidden visible--tablet sg-panel"><code>hidden--tablet visible--desktop</code></div>
```

A div that's visible on mobile, hidden at tablet, and visible again at desktop

```
<div class="hidden--tablet visible--desktop sg-panel"><code>hidden--tablet visible--desktop</code></div>
```

Visible classes can also be inline and inline-block. Just use classes such as `visible-inline-block--tablet`.

*/

.hidden {
	@include hidden;
}

.visible {
	@include visible;
}

.visible-inline {
	@include visible('inline');
}

.visible-inline-block {
	@include visible('inline-block');
}

.visible-td {
	@include visible('table-cell');
}

@each $name, $min-width in $breakpoints {

	@include mq($name) {

		.hidden--#{$name} {
			@include hidden;
		}

		.visible--#{$name} {
			@include visible;
		}

		.visible-inline--#{$name} {
			@include visible('inline');
		}

		.visible-inline-block--#{$name} {
			@include visible('inline-block');
		}

		.visible-td--#{$name} {
			@include visible('table-cell');
		}

	}

}
