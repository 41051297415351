/*---

Simple classes for making the direct children of an element either `display: inline / inline-block;`

```
<div class="row flush-first--1">
	<div class="column">
		<div class="inline-children">
			<div>Child item</div>
			<div>Child item</div>
			<div>Child item</div>
			<div>Child item</div>
		</div>
	</div>

	<div class="column">
		<div class="inline-children--block">
			<div>Child item</div>
			<div>Child item</div>
			<div>Child item</div>
			<div>Child item</div>
		</div>
	</div>
</div>
```

*/

.inline-children {
    > * {
        display: inline;
    }

    &--block {
        > * {
            display: inline-block;
        }
    }
}
