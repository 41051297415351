/*---

```
<div class="pagination">
	<ol>
		<li class="jump jump--first jump--disabled">
			<a href="#0">First</a>
		</li><!--
		--><li class="jump jump--prev jump--disabled">
			<a href="#0">Prev</a>
		</li><!--
		--><li class="page page--current">
			<a href="#0">1</a>
		</li><!--
		--><li class="page">
			<a href="#0">2</a>
		</li><!--
		--><li class="page">
			<a href="#0">3</a>
		</li><!--
		--><li class="gap">
			<span>&hellip;</span>
		</li><!--
		--><li class="page page--end">
			<a href="#0">32</a>
		</li><!--
		--><li class="page page--end">
			<a href="#0">33</a>
		</li><!--
		--><li class="jump jump--next">
			<a href="#0">Next</a>
		</li><!--
		--><li class="jump jump--last">
			<a href="#0">Last</a>
		</li>
	</ol>
</div>
```

*/

.pagination {
	line-height: 1;

	ol {
		text-align: center;
	}

	li {
		display: inline-block;
		margin-left: $spacing-unit--x-small;

		&:first-child {
			margin-left: 0;
		}
	}

	a,
	span {
		display: block;
		font-size: $p--small;
		line-height: 1;
	}

	a {
		background-color: $c--blue;
		color: $c--white;
		padding: $spacing-unit--x-small;
		text-decoration: none;

		&:hover,
		&:focus {
			background-color: $c--black;
			color: $c--white;
		}
	}

	.jump {
		&--disabled {
			a {
				background-color: $c--grey-light;
				color: $c--grey;
				cursor: not-allowed;
			}
		}
	}

	.page {
		&--current {
			a {
				background-color: $c--brand-primary;
				color: $c--white;
			}
		}
	}

	@include mq($to: tablet) {
		.jump--first,
		.jump--last,
		.page--end,
		.gap {
			display: none;
		}
	}
}
