/*---

```
<div class="table-wrapper">
	<table>
		<caption>A useful description of what is in this table</caption>
		<thead>
			<th scope="col">Nulla pariatur</th>
			<th scope="col">Perspiciatis</th>
			<th scope="col">Natus error</th>
			<th scope="col">Inventore</th>
			<th scope="col">Accusantium</th>
		</thead>
		<tbody>
			<tr>
				<td scope="row">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo quod distinctio aperiam magnam dicta nesciunt et amet quia necessitatibus cupiditate voluptates libero, ea vero aliquid neque perspiciatis mollitia, impedit possimus.</td>
				<td>Tempore ipsa consequuntur ipsum cum voluptatibus, corrupti alias. Laudantium deserunt magnam reiciendis labore esse eius voluptas atque, officia, numquam, eaque voluptatibus aliquid.</td>
				<td>Maiores enim porro voluptatibus illum nobis id ea consequuntur, officiis quod voluptates adipisci dolorem iure aut, maxime rerum sed temporibus fugiat! Ad.</td>
				<td>Soluta vel ad recusandae vero nesciunt velit ipsa odit nihil, consequatur expedita officiis? Aspernatur hic vero veritatis unde voluptatum, adipisci recusandae laborum?</td>
				<td>Qui mollitia delectus iste veniam, quos sequi sint numquam, ipsa suscipit dicta illum et saepe architecto ipsam ab quaerat eum, impedit doloribus.</td>
			</tr>
			<tr>
				<td scope="row">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo quod distinctio aperiam magnam dicta nesciunt et amet quia necessitatibus cupiditate voluptates libero, ea vero aliquid neque perspiciatis mollitia, impedit possimus.</td>
				<td>Tempore ipsa consequuntur ipsum cum voluptatibus, corrupti alias. Laudantium deserunt magnam reiciendis labore esse eius voluptas atque, officia, numquam, eaque voluptatibus aliquid.</td>
				<td>Maiores enim porro voluptatibus illum nobis id ea consequuntur, officiis quod voluptates adipisci dolorem iure aut, maxime rerum sed temporibus fugiat! Ad.</td>
				<td>Soluta vel ad recusandae vero nesciunt velit ipsa odit nihil, consequatur expedita officiis? Aspernatur hic vero veritatis unde voluptatum, adipisci recusandae laborum?</td>
				<td>Qui mollitia delectus iste veniam, quos sequi sint numquam, ipsa suscipit dicta illum et saepe architecto ipsam ab quaerat eum, impedit doloribus.</td>
			</tr>
			<tr>
				<td scope="row">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo quod distinctio aperiam magnam dicta nesciunt et amet quia necessitatibus cupiditate voluptates libero, ea vero aliquid neque perspiciatis mollitia, impedit possimus.</td>
				<td>Tempore ipsa consequuntur ipsum cum voluptatibus, corrupti alias. Laudantium deserunt magnam reiciendis labore esse eius voluptas atque, officia, numquam, eaque voluptatibus aliquid.</td>
				<td>Maiores enim porro voluptatibus illum nobis id ea consequuntur, officiis quod voluptates adipisci dolorem iure aut, maxime rerum sed temporibus fugiat! Ad.</td>
				<td>Soluta vel ad recusandae vero nesciunt velit ipsa odit nihil, consequatur expedita officiis? Aspernatur hic vero veritatis unde voluptatum, adipisci recusandae laborum?</td>
				<td>Qui mollitia delectus iste veniam, quos sequi sint numquam, ipsa suscipit dicta illum et saepe architecto ipsam ab quaerat eum, impedit doloribus.</td>
			</tr>
		</tbody>
	</table>
</div>
```

*/

.table-wrapper {
	max-width: 100%;
	overflow-x: auto;
}

table {
	border: 1px solid $c--grey-light;
	border-top: none;
	border-collapse: collapse;
	border-spacing: 0;
	font-size: $p;
	line-height: $lh--small;
	width: 100%;

	caption {
		@include vh;
	}
}

th,
td {
	@include soft--ends($spacing-unit--small);
	border-top: 1px solid $c--grey-light;
	padding-right: $spacing-unit--small;
	vertical-align: top;

	&:first-child {
		padding-left: $spacing-unit--small;
	}
}

th {
	font-weight: $w--bold;
	text-align: left;
}
