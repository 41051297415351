@keyframes shake {
    0% { transform: rotate(0deg); }
    1% { transform: rotate(-10deg); }
    2% { transform: rotate(-2deg); }
    3% { transform: rotate(8deg); }
    4% { transform: rotate(1deg); }
    5% { transform: rotate(-7deg); }
    6% { transform: rotate(2deg); }
    7% { transform: rotate(9deg); }
    8% { transform: rotate(3deg); }
    9% { transform: rotate(-4deg); }
    10% { transform: rotate(0deg); }
    100% { transform: rotate(0deg); }
}

.fancybox-active { height: auto !important; overflow: auto  !important; }
.fancybox-slide { padding: $spacing-unit--small; }
.fancybox-youtube { padding: $spacing-unit--x-large*2 $spacing-unit; }
.fancybox-bg { background: #fff; transition-timing-function: ease-in-out !important; }
.fancybox-is-open .fancybox-bg { opacity: 1; }
.fancybox-credits .fancybox-bg { opacity: .7; background: #000; }
.fancybox-container .fancybox-close-small {
	left: 100%; top: 0; margin-top: -25px; margin-left: -25px; width: 50px; height: 50px; border-radius: 100%; 
	background: $c--brand-primary; color: $c--white; border: none;
	background: linear-gradient(45deg, #c30e8f 0%,#e50054 28%,#f39200 100%);
	svg {
		fill: #fff; opacity: 1;
	}
	@include mq($to: tablet-small){
		display: none;
	}
}

#elastic-svg {
	display: none;
	position: fixed; top: -20vh; left: 0;
    width: 240px; height: 400vh;
	pointer-events: none;
	z-index: 665;
	@include mq($to: 1024px) { display: none !important; }
}

#site-wrapper {
	overflow: hidden;
	.site-content {
		position: relative; overflow: hidden;
		//z-index: z('site-content');
		
		section {
			display: none;
			&.past { 
				header .text p.when small { background: #000;  }
			}
			img {
				opacity: 1;
				transition: opacity 0.3s;
			}
			img[data-src] {
				opacity: 0;
			}
			header {
				text-transform: uppercase; position: relative; @include cf; text-align: left; margin-top: -136px; padding-top: 146px; padding-bottom: 135px;
				@extend .container;
				@include mq($to: tablet){
					margin: 0 $spacing-unit $spacing-unit--x-large; padding: 0;
				}
				.text {
					@include cf; position: absolute; top: 136px; left: 17%; padding-top: $spacing-unit--large*2; transform: translateX(-50%); z-index: 2; width: 550px;
					@include mq($to: desktop-wide){
						transform: none; left: $spacing-unit; padding-top: $spacing-unit--x-large;
					}
					@include mq($to: tablet){
						position: static; padding: 0; transform: none; text-align: center; width: 100%;
						br {
							display: none;
						}
					}

					.h2 {
						margin: 0 0 $spacing-unit--x-large $spacing-unit--large*2;
						a {
							display: inline-block; color: #fff; text-decoration: none; line-height: .9; padding: .25em .4em .1em; position: relative; z-index: 1; margin: 5px 0 0; transform: rotate(-1deg); @include jxGradient; background-size: 200%; animation: gradientMove 20s ease infinite; font-size: rem(70px);
						}
					}
					p {
						text-align: left; clear: both; float: left; margin: 0 0 $spacing-unit--large;
						@include mq($to: tablet){ float: none; text-align: center; }
						&.when {
							text-transform: uppercase; line-height: 1; transform: skew(-2deg) rotate(-2deg); padding: $spacing-unit; box-shadow: 0 0 20px 10px rgba(0,0,0,.075); background: $c--white;
							@include mq($to: tablet){ transform: none; box-shadow: none; padding: 0; }
							strong {
								display: block; font-size: rem(25px);
							}
							time {
								display: block; font-size: rem(59px); font-weight: $w--bold;
							}
							small {
								display: inline-block; font-size: rem(24px); color: $c--white; padding: .25em .4em .1em; @include jxGradient; background-size: 200%; animation: gradientMove 10s ease infinite; letter-spacing: 1px;
							}
							strong, time, small {
								@include mq($to: tablet){ display: none; }
							}
							span { 
								display:  block; text-transform: none; margin-top: 20px; font-weight: 400; line-height: 1.24; font-size: rem(19px);
								strong {
									display: inline; font-weight: 700; font-size: 1em;
								}
								@include mq($to: tablet){ margin: 0; }
							}
							.button { margin-top: 20px; }
						}
						
						&.descr {
							text-transform: none; padding: $spacing-unit; color: $c--white; background: $c--black; font-size: rem(19px); transform: rotate(-1deg); margin-bottom: $spacing-unit--x-large;
						}
					}
				}
				.teaser-wrapper {
					width: 66%; height: auto; z-index: 1; overflow: hidden; margin: 0 auto; position: relative;
					@include mq($to: tablet){ width: 100%; }
					.teaser-video {
						display: none;
					}
					.play {
						width: 260px; height: 260px;
						@include mq($to: tablet-small){
							width: 100px; height: 100px;
						}
					}
					.teaser {
						display: block; margin: 0 auto; position: relative; background: none; color: $c--white; outline: none; font-size: rem(15px); letter-spacing: 1px; width: 100%; padding: 0; overflow: hidden; 
						&:before {
							content: ""; display: block; padding-bottom: 100%; 
						}
						&:after {
							display: block; content: ""; position: absolute; left: 0; bottom: 0; width: 100%; height: 100%; pointer-events: none;
							background: url(/assets/img/bg-teaser.png) no-repeat bottom 0 left 0; background-size: 100% auto; z-index: 0;
						}
						
						video.bg, img {
							display: block; pointer-events: none; @include align(true, true, absolute); z-index: 0; min-width: 100%; min-height: 100%;
						}
						&:hover {
							box-shadow: 0 0 20px 10px rgba(0,0,0,.075);
						}
					}
					.rsvp {
						@include align(false, true, absolute); bottom: $spacing-unit--x-large; z-index: 3; white-space: nowrap;
						padding: 1.25rem 2.25rem;
						transition: all 0.4s cubic-bezier(0.94,.01,.72,.47);
						&:after {
							content: '\1F389\00A0\00A0';
							display: inline-block;
							position: absolute; right: -10px;
							font-size: 20px;
							opacity: 0;
							transform-origin: 0% 100%;
							transition: all 0.6s cubic-bezier(0.75, 0, 0.125, 1);
						}
						&:hover {
							padding: 1.25rem 3.25rem 1.25rem 1.25rem;
							&:after {
								opacity: 1;
								margin-top: 0; right: 5px;
								animation: shake 3s 0.4s infinite; 
							}
						}
						@include mq($to: tablet){
							bottom: $spacing-unit;
						}
					}
				}
			}
			
			article {
				@extend .container; @include cf; text-align: left;
				position: relative; padding: 0 $spacing-unit; margin-bottom: 90px;

				.loading {
					position: absolute; top: 50%; left: 50%; width: 400px; height: 200px; transform: translateX(-50%) translateY(-40%); z-index: 0; pointer-events: none; transition: opacity .3s; display: block; fill: none; stroke: #eee; stroke-linecap: round; stroke-width: 3%;
					@keyframes go { to { stroke-dashoffset: 0px; } }
					use {
						animation: go 2s linear infinite;
					}
				}

				&:not(.fullsize-media){ 
					min-height: 100vh; 
					.metas, .medias { opacity: 0; } 
				}

				@include mq($to: tablet){
					height: auto !important; padding: 0; margin: 0 $spacing-unit $spacing-unit--x-large !important;
				}

				.h3 {
					position: relative; margin: 0 0 0 220px; line-height: 1;
					display: block; text-decoration: none; font-weight: 500; @include cf;
					&:nth-child(1){
						z-index: 666;
 					}
					em {
						display: block; float: left; padding: $spacing-unit--x-small/2 $spacing-unit--x-small/2 $spacing-unit--x-small/4; font-size: rem(16px); text-transform: uppercase; color: $c--white; letter-spacing: 1px; font-style: normal; @include jxGradient; background-size: 200% !important; animation: gradientMove 10s ease infinite; font-weight: 300; white-space: nowrap;
						span {
							display: inline-block; margin-right: $spacing-unit--x-small;
						}
						&:after {
							content: "\A"; white-space: pre; 
						}
					}
					strong {
						display: block; float: left; clear: both; font-size: rem(90px); margin-top: 10px;
						@include jxGradient; background-size: 200% !important; animation: gradientMove 10s ease infinite;
						-webkit-background-clip: text !important;
						-webkit-text-fill-color: transparent !important;
					}
					@include mq($to: tablet){
						margin-left: 0; text-align: center;
						em {
							display: inline-block; float: none; width: 100%; margin-bottom: $spacing-unit--x-small; transform: rotate(-1deg); font-size: 0.8rem;
							span {
								display: block; margin: 0 0 5px;
							}
    					}
    					strong {
	    					float: none; font-size: 2rem;
    					}
						br { display: none; }
					}
				}
				
				.medias {
					position: absolute; top: 0; left: $spacing-unit; width: calc(100% - #{$spacing-unit*2}); transition: opacity .6s;
					> * {
						position: absolute; display: block; max-width: 100%;
					}
					iframe {
						border: 0;
					}
					.mobile--only {
						display: none;
					}
					.sound {
						@include jxGradient; background-size: 200%; animation: gradientMove 10s ease infinite; padding: $spacing-unit--small;
						position: static; max-width: none; width: 80%; margin: 0 auto; height: 220px;
						> * {
							margin: 0 auto; position: static; width: 100%;
						}
						@include mq($to: tablet){ display: none; }
					}
					video, .as-video {
						box-shadow: 0 0 20px 10px rgba(0,0,0,.075);
						&.no-shadow {
							box-shadow: none;
						}
						@include mq($to: tablet){
							box-shadow: none;
						}
					}
					.rotating {
						animation: rotating 15s linear infinite;
					}
					.rotating-inverted {
						animation: rotating-inverted 15s linear infinite;
					}
					
					#hypnotic {
						animation: rotating 4s infinite linear;
						img {
							display: block; max-height: 100%; 
							&:first-child {
								height: 500px;
							}
							&:last-child {
								position: absolute; top: 55.555555552px; left: 64.54248366px; height: 279.411764706px; animation: rotating 4s infinite linear;
							}
						}
					}
					
					#boney-m-video {
						&:before {
							display: block; content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url(/assets/img/video-boneym-gradient.png) no-repeat 50% 50%; background-size: cover;
						}
					}
					
					#hypno-pizza {
						position: absolute; left: 150px; top: 608px; width: 348px; height: 348px; 
						video { border-radius: 100%; @extend .no-shadow; }
						img {
							position: absolute; top: 50%; left: 50%; transform: translateX(-50%) translateY(-50%);
						}
					}
					
					#bubbles {
						position: absolute; left: 110px; top: 671px; width: 483px; height: 337px;
					}
					.bubble {
					    width: 42px; height: 42px;
					    background: linear-gradient(to right, #5ec16d 0%, #42be89 100%);
					    border-radius: 200px;
					    position: absolute;
					}
					
					.x1 {
					    width: 42px; height: 42px; left: 173px;
					    opacity: 0.85;
					    animation: moveclouds 15s linear infinite, sideWays 4s ease-in-out infinite alternate;
					}
					
					.x2 {
					    width: 31px; height: 31px; left: 215px;
					    background: linear-gradient(to right, #42be89 0%, #2bbd9d 100%);
					    opacity: 0.9;
					    animation: moveclouds 25s linear infinite, sideWays 5s ease-in-out infinite alternate;
					}
					.x3 {
					    width: 31px; height: 31px; left: 277px;
					    background: linear-gradient(to right, #10bbaf 0%, #00b7cd 100%);
					    opacity: 0.6;
					    animation: moveclouds 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
					}
					.x4 {
					    width: 130px; height: 130px; left: 9px;
					    background: linear-gradient(to right, #b8ca00 0%, #74c455 100%);
					    opacity: 0.5;
					    animation: moveclouds 18s linear infinite, sideWays 2s ease-in-out infinite alternate;
					}
					.x5 {
						display: none;
					    left: 150px;
					    transform: scale(0.8);
					    opacity: 0.3; 
					    animation: moveclouds 7s linear infinite, sideWays 1s ease-in-out infinite alternate;
					}
					@keyframes moveclouds { 
					    0% { 
					        top: 260px;
					    }
					    100% { 
					        top: -100px;
					    }
					}
					
					@keyframes sideWays { 
					    0% { 
					        margin-left:0px;
					    }
					    100% { 
					        margin-left:50px;
					    }
					}

					.text-wave {
						color: #fff; font-size: rem(103px); text-transform: uppercase; margin: 0; position: absolute; left: 0px; top: 330px; font-weight: 700; text-shadow: 0px 0px 10px (rgba(#000, .2))
					}
					
					#disk { left: 70px; top: 315px; }
					.vinyl {
						transition: all .4s cubic-bezier(.47,.16,.33,.96);
						&#disk { 
							z-index: -1; 
							> img { transition: all .4s cubic-bezier(.47,.16,.33,.96);; transform: rotateZ(-180deg); }
						}
						&.ready-to-play {
							&#disk { 
								left: 270px !important;
								> img { transform: rotateZ(0); } 
							}
							&#case { transform: rotateZ(-8deg); top: 310px !important; left: -20px; }
						}
						&.playing {
							&#disk { 
								left: 270px !important;
								animation: rotating 4s linear infinite;
								> img { transform: rotateZ(0deg) !important; }
							}
							&#case { transform: rotateZ(-8deg); top: 310px !important; }
						}
						&.paused {
							&#disk {
								animation: rotating 4s linear infinite;
								animation-play-state: paused;
							}
						}
					}
					.ready-to-play {
						&#disk { left: 265px; transform: rotateZ(0); }
						&#case { transform: rotateZ(-8deg); top: 310px; left: -20px; }
					}
					.playing {
						&#disk { left: 265px; transform: rotateZ(0); animation: rotating 4s linear infinite; animation-play-state: running; }
						&#case { transform: rotateZ(-8deg); top: 310px; }
					}
					.paused {
						&#disk {  
							animation: rotating 4s linear infinite; animation-play-state: paused;
						}
					}
					@include mq($to: tablet){
						position: relative; top: 0; left: 0; width: 100%; height: auto;
						> * {
							position: static; display: none;
							&.mobile {
								display: block; width: 100%; height: auto !important; max-width: none; z-index: 1;
								position: relative; top : 0 !important; bottom: auto !important; left: 0 !important; right: auto !important;
								img, video {
									margin: 0 auto; box-shadow: none; width: 100%; height: auto;
								}
								&#mobile--bg-1988 {
									width: calc(100vw); position: absolute; bottom: -$spacing-unit !important; left: -$spacing-unit !important; right: auto !important; top: auto !important; z-index: 0;
 								}
								&#mobile--bg-1989 {
									width: calc(120vw); left: -$spacing-unit !important; margin-left: -5vw; margin-top: -$spacing-unit--x-large; z-index: -1;
 								}
 								&#mobile--rotating-1989 {
	 								width: 300px !important; height: 300px !important; position: absolute; left: $spacing-unit !important; top: $spacing-unit--x-large !important;
 								}
 								&#mobile-1996 {
	 								position: absolute; width: auto; height: 275px !important; top: 100% !important; margin-top: -88px; left: 50% !important; margin-left: -120px;
									@include mq($to: phone-wide){
										height: 185px !important;
									}
 								}
 								&#mobile-2002 {
	 								position: absolute; top: 0; width: 80%; left: 10% !important;
 								}
 								&#mobile-fire-1, 
 								&#mobile-fire-2 {
	 								position: absolute; width: 30%; z-index: 2;
 								}
 								&#mobile-fire-1 { top: -30px !important; right: 0 !important; left: auto !important; bottom: auto !important;}
 								&#mobile-fire-2 { top: auto !important; right: auto !important; left: 0 !important; bottom: -20px !important;}
 								&#mobile-pati {
	 								position: absolute; top: auto !important; bottom: -10% !important; width: 50%; left: 10% !important;
 								}
 								&#mobile-2011 {
 									width: 100% !important; transform: none !important;
 								}
 								&#mobile-2018, &#mobile-btn-2018 {
 									width: 100% !important; transform: none !important;
 								}
 								&#mobile-btn-2018 { margin-top: rem(4px); }
 								&#mobile-2011-logo {
	 								position: absolute; top: -12% !important; bottom: auto !important; width: 30%; right: 2% !important; left: auto !important;
 								}
 								&.sound {
	 								width: 100% !important; position: static !important;
 								}
							}
						}
						.mobile--only {
							display: block;
						}
					}
				}
				
				.metas {
					position: absolute; z-index: 665; background: $c--black; color: $c--white; padding: $spacing-unit--small; font-weight: $w--light; font-size: rem(16px); max-width: 420px;
				
					&#year-1988 { 
						top: 278px; right: 320px;
					}
					&#year-1989 { 
						top: 288px; right: 185px;
					}
					&#year-1990 { 
						left: 0; top: 173px;
					}
					&#year-1996 { 
						left: 450px; top: 398px;
					}
					&#year-1998 { 
						right: 90px; top: 280px;
					}
					&#year-1999 { 
						left: 560px; top: 285px;
					}
					&#year-2002 { 
						left: 110px; top: 460px;
					}
					&#year-2002-2 { 
						right: 150px; top: 980px;
					}
					&#year-2005 { 
						left: 0px; top: 333px;
					}
					&#year-2006 { 
						right: 225px; top: 220px;
					}
					&#year-2008 { 
						left: 105px; top: 285px;
					}
					&#year-2009 { 
						right: 150px; top: 315px;
					}
					&#year-2011 { 
						right: 150px; top: 610px;
					}
					&#year-2013 { 
						left: 0; top: 360px;
					}
					&#year-2014 { 
						left: 300px; top: 280px;
					}
					&#year-2018 { 
						right: 96px; top: 320px;
					}
					
					@include mq($to: tablet){
						position: static; background: none; color: $c--black; padding: 0; font-weight: $w--normal; max-width: none; margin-bottom: $spacing-unit; transform: none !important; margin-top: $spacing-unit; font-size: rem(15px);
						br { display: none; }
					}
				}
				
				&.imagesloaded {
					min-height: 0;
					.loading {
						opacity: 0;
					}
					.metas, .medias {
						opacity: 1;
					}
				}
	
				&#premiere-soiree-1988 {
					margin-bottom: 105px;
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	

					@include mq($to: tablet){
						margin-bottom: $spacing-unit--large*2 + $spacing-unit !important;
					}
				}
				&#demenagement {
					margin-bottom: 88px;
					.h3 a em { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					.h3 a strong { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
				}
				&#developpement {
					margin-bottom: 147px;
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	
				}
				&#renouveau {
					margin-bottom: 0px;
					.h3 a em { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					.h3 a strong { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					@include mq($to: tablet){
						.medias {
							margin-bottom: 187px;
						}
					}
					@include mq($to: phone-wide){
						.medias {
							margin-bottom: 97px;
						}
					}
				}
				&#dix-ans {
					margin-bottom: 258px;
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	
					@include mq($to: tablet){
						margin-bottom: $spacing-unit--large*2 + $spacing-unit !important;
					}
				}
	
				&#ou-sont-les-femmes {
					margin-bottom: 110px;
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	
				}
				&#dj-stars {
					margin-bottom: 120px; background: transparent;
					.h3 a em { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					.h3 a strong { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					@include mq($to: tablet){
						.medias {
							padding-top: 16%;
						}
					}
				}
				&#incendie {
					margin-bottom: 67px; 
					&:before {
						display: block; content: ""; position: absolute; z-index: -1; top: -100px; left: -100vw; width: 200vw; height: 500px; pointer-events: none;
						background: linear-gradient(to top, #e50041 0%, #f39200 41%, rgba(#fff, 0) 100%); background-position: 0 80px; background-repeat: no-repeat;
						animation: fireMove 5s ease infinite;
	 				}
					.h3 a em { background: linear-gradient(to right, #f39200 0%, #e50041 100%); }	
					.h3 a strong { 
						background: none;
						span {
							-webkit-background-clip: text !important;
							-webkit-text-fill-color: transparent !important;
							display: block; background: linear-gradient(to bottom, #fff 0%, #fff 60%, rgba(#fff, 0.5) 70%, rgba(#fff, 0) 100%);
						}	
					}
					@include mq($to: tablet){
						&:before {
							display: none;		
						}
					}
				}
				&#patinoire {
					margin-bottom: 56px; background: transparent;
					.h3 a em { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					.h3 a strong { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
				}
				&#apres-20-ans {
					margin-bottom: 258px;
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	
				}
				&#du-neuf {
					margin-bottom: 100px; 
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	
				}
				&#bella-ciao {
					margin-bottom: 100px;
					.h3 a em { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					.h3 a strong { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
				}
				&#warm-up {
					margin-bottom: 100px; 
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	
				}
				&#plus-de-jeux {
					margin-bottom: 150px;
					.h3 a em { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					.h3 a strong { background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%); }	
					@include mq($to: tablet){ margin-bottom: 2rem !important; }
				}
				&#et-apres {
					margin-bottom: 230px; 
					.h3 a em { background: linear-gradient(to right, #00a9e4 0%, #e50076 100%); }	
					.h3 a strong { background: linear-gradient(to right, #00a9e4 0%, #e50076 57%, #f39200 100%); }	
					.button--photo {
						left: 50%; transform: translateX(-50%); top: 1003px;
					}
				}
				
				&:first-of-type {
					text-align: center;
					.h3 {
						margin: 0 auto $spacing-unit; text-align: center; display: inline-block; font-size: rem(24px); color: $c--white; padding: .25em .4em .1em; letter-spacing: 1px; transform: rotate(-1deg); color: $c--white; background: linear-gradient(to right, #c1c910 0%, #07b7cc 100%);
						a {
							color: inherit;
						}
					}
					img {
						display: block; margin: 0 auto $spacing-unit;
					}
					p {
						text-align: center; font-size: rem(27px); margin: 0; font-weight: $w--normal; line-height: 1.4;
						em {
							font-style: normal; font-weight: $w--bold;
						}
						strong {
							display: inline-block; color: $c--white; padding: .25em .4em .1em; @include jxGradient; background-size: 200%; animation: gradientMove 10s ease infinite; letter-spacing: 1px; text-transform: uppercase;
						}
						@include mq($to: tablet){ 
							text-transform: none; font-weight: $w--normal; font-size: rem(18px); line-height: 1.3;
							br {
								display: none;
							}
							strong {
								display: inline; padding: 0; letter-spacing: 0; background: none; color: $c--black; font-weight: $w--bold; &:after { content: "."; }
							}
						}
					}
					.medias {
						margin-top: 78px;
						position: static; width: 100%; height: auto;
						> * {
							position: static; margin: 0 auto $spacing-unit--x-large;
							&:last-child {
								margin-bottom: 0;
							}
						}
						@include mq($to: tablet){
							margin-top: $spacing-unit;
							.sound {
								display: block; width: 100% !important;
							}
						}
					}
				}
			}
			
			footer {
				padding-bottom: 132px;
				@include mq($to: tablet){
					padding-bottom: $spacing-unit--x-large;
				}
				> * {
					margin: 0 $spacing-unit--x-small $spacing-unit--small;
					&last-of-type {
						margin-bottom: 0;
					}
					@include mq($to: tablet){ 
						width: calc(100% - #{$spacing-unit*2});
					}
				}
			}
	
			&.on {
				display: block;
			}
			
			&#decade-1998-2008 {
				header .teaser-wrapper .teaser {
					&:after {
						background-image: url(/assets/img/bg-teaser-2.png);
					}
				}
			}
			
			&#decade-2008-2018 {
				header .teaser-wrapper .teaser {
					&:after {
						background-image: url(/assets/img/bg-teaser-2.png);
					}
				}
			}
			
			span.play-button--green:before {
				background: linear-gradient(to right, #4fb66f 0, #408d8e 100%) !important; box-shadow: 0 0 65px 0 rgba(#4fb66f, .63) !important;
			}
			
			span.play-button--purple:before {
				background: linear-gradient(to right, #3970d5 0, #a12876 100%) !important; box-shadow: 0 0 65px 0 rgba(#3970d5, .63) !important;
			}
			
			.video-overlay {
				outline: none;
				video { display: block;}
			}
			.play {
				@include align(true, true, absolute);
				width: 160px; height: 160px;
				z-index: 3;
				cursor: pointer;
				@include mq($to: tablet) {
					width: 100px; height: 100px;
				}
				.blob-path {
					shape-rendering: crispEdges;
					animation: rotating 40s linear infinite;
					transform-origin: 50% 50%;
				}
			}
		}
		
		.footer {
			padding-bottom: $spacing-unit--x-large; text-transform: uppercase; font-size: rem(15px); font-weight: 500;
			a {
				color: $c--black; text-decoration: none; margin: 0 $spacing-unit--small;
			}
			h4 {
				display: inline-block; clear: both; font-size: rem(20px); margin-bottom: $spacing-unit; font-weight: 500;
				background: linear-gradient(to right,#00a9e4 0,#e50076 57%,#f39200 100%);
				background-size: 200% !important; animation: gradientMove 10s ease infinite;
 				-webkit-background-clip: text !important;
				-webkit-text-fill-color: transparent !important;
			}
			.partners {
				clear: both; margin: 0 auto $spacing-unit--x-large*2; padding: 0; text-align: center; @extend .container;
				@include mq($to: tablet-small){
					margin-bottom: $spacing-unit--x-large;
				}
				li {
					display: inline-block; margin: 0 $spacing-unit--small $spacing-unit--x-small; position: relative; height: 100px; line-height: 100px;
					&:nth-child(-n + 2){ display: block; float: left; width: 50%; margin-left: 0; margin-right: 0; img { display: block; } }
					&:nth-child(1) {
						img {
							margin-left: auto; margin-right: $spacing-unit;
						}
					}
					&:nth-child(2) {
						img {
							margin-right: auto; margin-left: $spacing-unit;
						}
					}
					img {
						display: inline; vertical-align: middle;
					}

					@include mq($to: tablet-small){
						display: inline-block !important; float: none !important; width: auto !important; height: auto !important; line-height: 1; margin: 0 $spacing-unit--small $spacing-unit !important;
						&:nth-child(-n + 2){ width: 100% !important; margin: 0 0 $spacing-unit !important; }
						img {
							margin: 0 auto !important; display: inline !important;
						}
					}
				}
			}
			@include mq($to: tablet-small){
				padding-bottom: $spacing-unit--x-large*2;
			}
		}

		footer {
			.button {
				svg { 
					overflow: visible; 
					path { transform-origin: 50% 100%; }
				}
				&:hover {
					svg path { animation: shake 3s linear infinite; }
				}
			}
		}
	
		.responsive-embed {
			position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; 
			iframe, object, embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
		}
		
		#credits {
			display: none;
		}
	}
}

#credits {
	padding: $spacing-unit--x-large; font-family: $stack;
	h4 {
		font-size: rem(30px); font-weight: 500; float: left; margin-bottom: $spacing-unit--large;
		@include jxGradient; background-size: 200% !important; animation: gradientMove 10s ease infinite;
		-webkit-background-clip: text !important;
		-webkit-text-fill-color: transparent !important;
	}
	ul {
		clear: both;
		margin: 0 0 $spacing-unit; padding: 0 $spacing-unit--large 0 0;
		font-size: rem(22px);
		li {
			ul {
				font-weight: 300;
			}
		}
	}
	.fancybox-close-small {
		top: $spacing-unit; right: $spacing-unit; left: auto; bottom: auto; margin: 0;
	}
}