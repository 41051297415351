/*---

Field list

```
<ul class="fields-list">
	<li>
		<input id="form-example-1-checkbox-1" type="checkbox" class="checkbox" checked>
		<label for="form-example-1-checkbox-1">Option 1</label>
	</li>

	<li>
		<input id="form-example-1-checkbox-2" type="checkbox" class="checkbox">
		<label for="form-example-1-checkbox-2">Option 2</label>
	</li>

	<li>
		<input id="form-example-1-checkbox-3" type="checkbox" class="checkbox" class="is-invalid">
		<label for="form-example-1-checkbox-3">Option 3 error</label>
	</li>

	<li>
		<input id="form-example-1-checkbox-4" type="checkbox" class="checkbox" disabled>
		<label for="form-example-1-checkbox-4">Option Disabled</label>
	</li>
</ul>
```

Inline field list

```
<ul class="fields-list-inline">
	<li>
		<input id="form-example-2-checkbox-1" type="checkbox" class="checkbox" checked>
		<label for="form-example-2-checkbox-1">Option 1</label>
	</li>

	<li>
		<input id="form-example-2-checkbox-2" type="checkbox" class="checkbox">
		<label for="form-example-2-checkbox-2">Option 2</label>
	</li>

	<li>
		<input id="form-example-2-checkbox-3" type="checkbox" class="checkbox" class="is-invalid">
		<label for="form-example-2-checkbox-3">Option 3 error</label>
	</li>

	<li>
		<input id="form-example-2-checkbox-4" type="checkbox" class="checkbox" disabled>
		<label for="form-example-2-checkbox-4">Option Disabled</label>
	</li>
</ul>
```

*/

// lists & groupings

.fields-list {
	> * {
		margin-top: $spacing-unit--small;

		&:first-child {
			margin-top: 0;
		}
	}
}

.fields-list-inline {
	&,
	&:first-child {
		margin-top: n($spacing-unit--small);
	}

	> * {
		margin-top: $spacing-unit--small;
		margin-right: $spacing-unit--small;
		display: inline-block;

		&:last-child {
			margin-right: 0;
		}
	}
}
